<template>
    <DashboardWrapper>
        <div class="container mx-auto">
            <div class="mx-4 sm:mx-8"> 
                <h2 class="text-white pb-4 mb-4 uppercase text-xl font-extrabold">
                    Wedstrijden
                </h2>

                <div class="flex justify-end">
                    <div @click="showModal('createMatch1')" class="my-auto text-white cursor-pointer flex group">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 mr-1 transition-all duration-150 group-hover:text-gray-400 transform group-hover:-translate-y-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"/>
                        </svg>
                        <p class="mr-6 group-hover:text-gray-400 transition-all duration-150">
                            Wedstrijd uploaden
                        </p>
                    </div>
                    <SearchInput v-model:value="searchQuery" placeholder="Wedstrijd zoeken..." theme="dark"/>
                </div>

                <div class="grid grid-cols-1 gap-10 sm:grid-cols-2 lg:grid-cols-3 mt-10">
                    <router-link v-for="match in sortedMatches" :key="match.id" :to="{ name: 'match.show', params: {id: match.id}}">
                        <MatchItem :match="match"/>
                    </router-link>
                </div>
            </div>
        </div>
    </DashboardWrapper>

    <!-- Upload Match Modal 1 -->
    <Modal v-if="modalVisible && selectedModal == 'createMatch1'" @closed="closeModal">
         <template v-slot:header>
            <div class="flex">
                <svg class="w-6 mr-1 text-scorelit-gray" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"/>
                </svg>
                <h2 class="uppercase text-xl font-extrabold">
                    Wedstrijd uploaden
                </h2>
            </div>
        </template>
        <template v-slot:body>
            <div class="mt-5 flex w-full flex-col items-center justify-center bg-grey-lighter">
                <label class="w-full flex flex-col items-center px-4 py-6 bg-white rounded-md shadow-lg tracking-wide uppercase border cursor-pointer hover:bg-gray-200 transition duration-200">
                    <svg class="w-8 h-8" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                        <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                    </svg>
                    <span v-if="form.file_name" class="mt-2 font-medium text-xs">{{ form.file_name }}</span>
                    <span v-else class="mt-2 font-medium text-xs">Selecteer je videobestand</span>
                    <input 
                        type="file" 
                        ref="file_upload"
                        name="file" 
                        @change="filesChange" 
                        accept="video/mp4,video/x-m4v,video/*"
                        class="hidden"
                    >
                </label>                        
            </div>
        </template>
        <template v-slot:footer> 
            <div class="mt-5 sm:mt-6 sm:flex sm:flex-row-reverse">
                <button @click="showModal('createMatch2')" type="button" class="button w-full inline-flex justify-center sm:ml-3 sm:w-auto ">
                    Volgende stap
                </button>
                <button @click="closeModal" type="button" class="button-black w-full inline-flex justify-center mt-3 sm:mt-0 sm:w-auto ">
                    Annuleren
                </button>
            </div>
        </template>    
    </Modal>

    <!-- Upload Match Modal 2 -->
    <Modal v-if="modalVisible && selectedModal == 'createMatch2'" @closed="closeModal">
         <template v-slot:header>
            <div class="flex">
                <svg class="w-6 mr-1 text-scorelit-gray" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"/>
                </svg>
                <h2 class="uppercase text-xl font-extrabold">
                    Wedstrijd uploaden
                </h2>
            </div>
        </template>
        <template v-slot:body>
            <TextInput label="Titel" v-model:value="form.file_name" border="enable" placeholder="Geef je wedstrijd een naam" class="mt-5"/>
            <SelectInput label="Team" border="enable" class="mt-5"/>
            <TextAreaInput label="Beschrijving" border="enable" placeholder="Extra informatie van deze wedstrijd" class="mt-5"/>
        </template>
        <template v-slot:footer> 
            <div class="mt-5 sm:mt-6 sm:flex sm:flex-row-reverse">
                <button @click="showModal('createMatch3')" type="button" class="button w-full inline-flex justify-center sm:ml-3 sm:w-auto ">
                    Volgende stap
                </button>
                <button @click="closeModal" type="button" class="button-black w-full inline-flex justify-center mt-3 sm:mt-0 sm:w-auto ">
                    Annuleren
                </button>
            </div>
        </template>    
    </Modal>

    <!-- Upload Match Modal 3 -->
    <Modal v-if="modalVisible && selectedModal == 'createMatch3'" @closed="closeModal">
         <template v-slot:header>
            <div class="flex">
                <svg class="w-6 mr-1 text-scorelit-gray" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"/>
                </svg>
                <h2 class="uppercase text-xl font-extrabold">
                    Wedstrijd uploaden
                </h2>
            </div>
        </template>
        <template v-slot:body>
            <SearchInput label="Coaches van deze wedstrijd" border="enable" placeholder="Coach zoeken..." class="mt-5"/>
            <SearchInput label="Spelers van deze wedstrijd" border="enable" placeholder="Speler zoeken..." class="mt-5"/>
        </template>
        <template v-slot:footer> 
            <div class="mt-5 sm:mt-6 sm:flex sm:flex-row-reverse">
                <button type="button" class="button w-full inline-flex justify-center sm:ml-3 sm:w-auto ">
                    Uploaden
                </button>
                <button @click="closeModal" type="button" class="button-black w-full inline-flex justify-center mt-3 sm:mt-0 sm:w-auto ">
                    Annuleren
                </button>
            </div>
        </template>    
    </Modal>
</template>

<script>
import DashboardWrapper from "@/views/wrappers/Dashboard";
import TextInput from "@/components/forms/TextInput";
import SearchInput from "@/components/forms/SearchInput";
import SelectInput from "@/components/forms/SelectInput";
import TextAreaInput from "@/components/forms/TextAreaInput";
import MatchItem from "@/components/rows/MatchItem";
import Modal from "@/components/modals/Modal";

export default {
    name: "Matches",
    components: {
        DashboardWrapper,
        TextInput,
        SearchInput,
        SelectInput,
        TextAreaInput,
        MatchItem,
        Modal,
    },

    data() {
        return {
            modalVisible: false,
            selectedModal: '',
            searchQuery: '',

            matches: [
                { id: 1,    title: 'Arrows U22 - Akrides U22-1',            thumbnail:'@/assets/images/logo/emblem.svg',        createdOn:'17 Februari 2020'    },
                { id: 2,    title: 'Arrows U16 - Bv Leeuwarden U16',        thumbnail:'@/assets/images/logo/emblem.svg',        createdOn:'14 Februari 2020'    },
                { id: 3,    title: 'Arrows U14 - Celeritas Donar U14',      thumbnail:'@/assets/images/logo/emblem.svg',        createdOn:'13 Februari 2020'    },
                { id: 4,    title: 'Arrows U18 - Hoogeveen U18',            thumbnail:'@/assets/images/logo/emblem.svg',        createdOn:'13 Februari 2020'    },
                { id: 5,    title: 'Arrows U12 - BV Sneek U12',             thumbnail:'@/assets/images/logo/emblem.svg',        createdOn:'9 Februari 2020'     },
            ],

            form:{
                title: "",
                description: "",
                age: "",
                file: undefined,
                file_name: "",
            },
        }
    },

    methods: {
        closeModal() {
            this.modalVisible = false;
        },

        showModal(type) {
            this.selectedModal = type;
            this.modalVisible = true;        
        },

        filesChange(){
            this.form.file = this.$refs.file_upload.files[0];

            this.form.file_name = this.$refs.file_upload.files[0].name;
        },
    },

    computed: {
        sortedMatches(){
            return this.resultQuery;
        },

        resultQuery(){
            if(this.searchQuery){
            return this.matches.filter(match=>{
            return this.searchQuery.toLowerCase().split(' ').every(v => match.title.toLowerCase().includes(v))
            })
            }
            else {
                return this.matches;
            }
        },
    },
}
</script>