<template>
    <!-- Match item-->
    <div class="shadow sm:rounded-md sm:overflow-hidden cursor-pointer group">
        <div class="overflow-hidden h-40 bg-scorelitorange">
            <img src="@/assets/images/logo/emblem.svg" alt="Scorelit logo" class="w-full h-40 object-cover transition duration-300 transform group-hover:-translate-y-1 group-hover:scale-105">
        </div>
        <div class="px-6 py-4 bg-scorelitgray sm:px-6 group-hover:bg-gray-800 transition duration-200">
            <div>
                <h3 class="text-white block font-bold truncate ...">
                    {{ match.title }}
                </h3>
                <p class="text-xs text-gray-500">
                    {{ match.createdOn }}
                </p>
            </div>  
        </div>
    </div>
</template>

<script>
export default {
    name: 'MatchItem',
    props: ['match'],
}
</script>